





























































































































































































import Vue from 'vue'

import Timeline from '@/components/ui/Timeline/Timeline.vue'
import Player from '@/components/ui/Player/Player.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import Legend from '@/components/map/Legend/Legend.vue'
import RainInformation from '@/components/data/forecast/rain/RainInformation.vue'
import WeatherStationListing from '@/components/data/weatherstation/WeatherStationListing.vue'
import ServiceInformation from '@/components/data/information/ServiceInformation.vue'
import ForecastMix from '@/components/data/forecast/ForecastMix.vue'
import { fetchWeatherStationDataFilteredByDate } from '@/store/weatherstation/store'

import { fetchRadarBbox } from '@/store/time/store'

import preferences from '@/services/preferences'
import { vrsStore } from '@/store'
import { WeatherEvent } from '@/store/event/definitions'
import { convertDate, stringifyDate } from '@/helpers/dates'
import { AppQS } from '@/store/app/definitions'

import MixinPageVisibility from '@/mixins/pageVisibility'
import { vrsStoreEvent } from '@/store/event/store'
import { computeForecastDataToDisplay } from '@/store/forecast/store'
import { computeInfosDataToDisplay } from '@/store/infos/store'
import { computeAvgTempDataToDisplay, fetchWeatherStationAverageTemperatureDataFilteredByDate } from '@/store/avgtemp/store'
import { LatLng } from 'leaflet'

export default Vue.extend({
  components: {
    'ui-timeline': Timeline,
    'ui-player': Player,
    'ui-loader': Loader,
    'map-mapbox': () => import('@/components/map/MapboxGL/MapboxGL.vue'),
    'map-leaflet': () => import('@/components/map/Leaflet/Leaflet.vue'),
    'map-legend': Legend,
    'forecast-rain-information': RainInformation,
    'weather-station-listing': WeatherStationListing,
    'service-information': ServiceInformation,
    'forecast-mix': ForecastMix
  },
  mixins: [
    MixinPageVisibility((isVisible) => {
      if (isVisible && vrsStore.state.app.data.live) {
        // fetchRadarTimestamps(
        //   vrsStore.state.event.data.id,
        //   vrsStore.state.event.data.timezone
        // )
      }
    })
  ],
  data: () => ({
    /** @type {boolean} */
    isPlaying: false,
    /** @type {boolean} */
    wasPlayingBeforeEvent: false,
    /** @type {number} */
    numberOfForwardLayers: 5,
    showLegend: false,
    event: vrsStore.state.event,
    time: vrsStore.state.time,
    forecast: vrsStore.state.forecast,
    information: vrsStore.state.information,
    weatherStation: vrsStore.state.weatherStation,
    app: vrsStore.state.app,
    config: vrsStore.state.config,
    displayLayers: {
      osm: preferences['layer-osm'] !== undefined ? preferences['layer-osm'] : true,
      rainpath: preferences['layer-rainpath'] !== undefined ? preferences['layer-rainpath'] : false,
      /* we init weatherStations with the config var */
      weatherStations: preferences['layer-weatherStations'] !== undefined ? preferences['layer-weatherStations'] : vrsStore.modules.auth.computed.displayNowcastingOptions().stations === true
    },
    view: preferences.view,
    frameRate: preferences.frameRate,
    animationLengths: [15, 30, 60, 120, 180, 240, 300, 360],
    osmOpacity: preferences.osmOpacity,
    radarOpacity: preferences.radarOpacity,
    isLayerWindArrowsDisplayed: preferences.isLayerWindArrowsDisplayed,
    replaySettings: vrsStore.state.app.data.replaySettings,
    centerPreferenceMain: preferences.events?.[vrsStore.state.event.data?.id]?.mapMainCenter,
    centerPreferenceZoom: preferences.events?.[vrsStore.state.event.data?.id]?.mapZoomCenter
  }),
  computed: {
    ...vrsStore.modules.time.computed,
    allDataTimeRangesLocal: vrsStore.computed.allDataTimeRangesLocal,
    displayNowcastingOptions: vrsStore.modules.auth.computed.displayNowcastingOptions,
    displayHeaderAverageTemperature: vrsStore.modules.auth.computed.displayHeaderAverageTemperature,
    imageResolution: vrsStore.modules.app.computed.imageResolution,
    timeoutBetweenImages () {
      return 1000 / this.frameRate
    },
    mapComponentToUse: vrsStore.modules.app.computed.mapComponentToUse,
    animationLength () {
      return this.animationLengths.indexOf(vrsStore.state.app.data.animationLength) !== -1 ? vrsStore.state.app.data.animationLength : 0
    },
    displayAside () {
      const DNO = this.displayNowcastingOptions
      return DNO.stations || DNO.info || DNO.rain
    },
    currentWeatherStationData () {
      // display latest data only when live
      if (
        !this.app.data.live &&
        this.replaySettings.enabled &&
        this.replaySettings.weatherstation
      ) {
        return this.weatherStation?.dataByTimestamp?.map(wsd => (wsd[this.currentTime.local]))
      }
      return this.weatherStation.latest
    }
  },
  methods: {
    /**
     * Update the selected range in URL (referenceDate, length)
     * Then, the router will update storeApp accordingly
     * and the selectedRangeLocal will be updated in store
     * and will be dispatched to all the components that need it
     */
    updateSelectedRangeLocal (newRange: [string, string], isLive: boolean) {
      const from = convertDate(newRange[0])
      const to = convertDate(newRange[1])
      const length = Math.round(to.diff(from, 'minutes').minutes)
      const query: AppQS = { ...this.$route.query, length }
      if (!isLive) {
        query.referenceDate = newRange[1]
      } else {
        delete query.referenceDate
      }
      this.$router.push({
        path: this.$route.path,
        query
      })
    },
    updateZoom (newZoom) {
      if (!newZoom) return
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          zoomTimeline: newZoom
        }
      })
    },

    changeAnimationLength (newValue: number) {
      if (this.animationLengths.indexOf(vrsStore.state.app.data.animationLength) !== -1) {
        preferences.defaultAnimationLength = newValue
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          length: newValue
        }
      })
    },

    goLive () {
      // refresh the current event, and so get the latest radar activity periods + obs°
      vrsStoreEvent.actions.fetchEvent(vrsStore.state.event.data.id)
      // just keep the length if it exist
      const query: AppQS = {}
      if (this.$route.query.length) {
        query.length = this.$route.query.length
      }
      delete query.mode
      this.$router.push({
        path: this.$route.path,
        query
      })
    },

    goSession (session) {
      const { start, end } = session
      const startDate = convertDate(start)
      const endDate = convertDate(end)
      const length = endDate.diff(startDate).as('minutes') + 60
      const referenceDate = stringifyDate(endDate.plus({ minutes: 30 }))
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          length,
          referenceDate
        }
      })
    },

    togglePlay () {
      this.isPlaying = !this.isPlaying
      this.isPlaying ? this.play() : this.stop()
    },

    toggleLayer (layerName) {
      this.displayLayers[layerName] = !this.displayLayers[layerName]
      preferences['layer-' + layerName] = this.displayLayers[layerName]
    },

    toggleArrows () {
      this.isLayerWindArrowsDisplayed = !this.isLayerWindArrowsDisplayed
      preferences.isLayerWindArrowsDisplayed = this.isLayerWindArrowsDisplayed
    },

    play () {
      vrsStore.state.time.indexTimesToDisplayUTC =
        (vrsStore.state.time.indexTimesToDisplayUTC + 1) % this.timesToDisplayUTC.length
      this.timeoutId = setTimeout(() => {
        this.play()
      }, this.timeoutBetweenImages)
    },
    stop () {
      clearTimeout(this.timeoutId)
    },

    fastBackward () {
      vrsStore.state.time.indexTimesToDisplayUTC = 0
    },
    backward () {
      if (vrsStore.state.time.indexTimesToDisplayUTC > 1) {
        vrsStore.state.time.indexTimesToDisplayUTC--
      }
    },
    forward () {
      if (vrsStore.state.time.indexTimesToDisplayUTC < this.timesToDisplayUTC.length - 1) {
        vrsStore.state.time.indexTimesToDisplayUTC++
      }
    },
    fastForward () {
      vrsStore.state.time.indexTimesToDisplayUTC = this.timesToDisplayUTC.length - 1
    },
    goToIndex (i) {
      if (i > 0 && i < this.timesToDisplayUTC.length) {
        vrsStore.state.time.indexTimesToDisplayUTC = i
      }
    },

    pauseAnimation () {
      this.wasPlayingBeforeEvent = this.isPlaying || this.wasPlayingBeforeEvent
      this.stop()
    },
    resumeAnimation (map: 'zoom' | 'main', center?: LatLng) {
      this.wasPlayingBeforeEvent && this.play()
      this.wasPlayingBeforeEvent = false
      /**
       * if center is set, memorize it in user pref
       * for this event, and for this map
       */
      if (center) {
        preferences.updateEventMapCenter(vrsStore.state.event.data.id, map, center)
        switch (map) {
          case 'zoom':
            this.centerPreferenceZoom = preferences.events?.[vrsStore.state.event.data.id]?.mapZoomCenter
            break
          case 'main':
            this.centerPreferenceMain = preferences.events?.[vrsStore.state.event.data.id]?.mapMainCenter
            break
        }
      }
    },
    onResetMapCenter () {
      preferences.updateEventMapCenter(vrsStore.state.event.data.id, 'zoom')
      preferences.updateEventMapCenter(vrsStore.state.event.data.id, 'main')
      this.centerPreferenceMain = preferences.events?.[vrsStore.state.event.data.id]?.mapMainCenter
      this.centerPreferenceZoom = preferences.events?.[vrsStore.state.event.data.id]?.mapZoomCenter
    },

    onOsmOpacityChange (value: number): void {
      this.osmOpacity = value
    },
    onRadarOpacityChange (value: number): void {
      this.radarOpacity = value
    },
    fetchData () {
      if (!vrsStore.state.event.data) return
      if (!this.selectedRangeLocal) return
      fetchWeatherStationDataFilteredByDate(
        vrsStore.state.event.data.stations.map(s => s.id.toString()),
        convertDate(this.selectedRangeLocal[0], vrsStore.state.event.data.timezone),
        convertDate(this.selectedRangeLocal[1], vrsStore.state.event.data.timezone)
      )
      fetchWeatherStationAverageTemperatureDataFilteredByDate(
        vrsStore.state.event.data.id,
        convertDate(this.selectedRangeLocal[0], vrsStore.state.event.data.timezone),
        convertDate(this.selectedRangeLocal[1], vrsStore.state.event.data.timezone)
      )
    },
    refreshReplayData () {
      if (!this.replaySettings.enabled) return
      if (this.replaySettings.forecast) {
        computeForecastDataToDisplay(
          this.currentTime.luxonDate,
          vrsStoreEvent.state.data.timezone,
          vrsStoreEvent.state.data.sessions
        )
      }
      if (this.replaySettings.information) {
        computeInfosDataToDisplay(this.currentTime.local)
      }
      if (this.replaySettings.weatherstationavgtemp) {
        computeAvgTempDataToDisplay(this.currentTime.local)
      }
    },
    resetForecastData () {
      const referenceDateLuxon = convertDate(vrsStore.modules.app.state.data.referenceDate, vrsStoreEvent.state.data.timezone)

      computeForecastDataToDisplay(
        referenceDateLuxon,
        vrsStoreEvent.state.data.timezone,
        vrsStoreEvent.state.data.sessions
      )
    },
    resetInformationData () {
      computeInfosDataToDisplay(vrsStore.modules.app.state.data.referenceDate)
    },
    resetAvgTempData () {
      computeAvgTempDataToDisplay(vrsStore.modules.app.state.data.referenceDate)
    }
  },
  beforeDestroy () {
    this.stop()
  },
  watch: {
    'event.data': {
      immediate: true,
      async handler (newValue: WeatherEvent) {
        if (newValue) {
          await fetchRadarBbox(newValue.id)
          this.centerPreferenceMain = preferences.events?.[vrsStore.state.event.data.id]?.mapMainCenter
          this.centerPreferenceZoom = preferences.events?.[vrsStore.state.event.data.id]?.mapZoomCenter
        }
      }
    },
    selectedRangeLocal: {
      immediate: true,
      handler (newRange, oldRange) {
        if (!newRange || !oldRange) return
        if (newRange[0] === oldRange[0] && newRange[1] === oldRange[1]) return
        this.fetchData()
      }
    },

    frameRate (newValue) {
      preferences.frameRate = newValue
    },
    view (newValue) {
      preferences.view = newValue
    },
    osmOpacity (newValue: number) {
      preferences.osmOpacity = newValue
    },
    radarOpacity (newValue: number) {
      preferences.radarOpacity = newValue
    },
    currentTime: 'refreshReplayData',
    replaySettings: {
      handler (newValue) {
        /**
       * if the user disable replay,
       * use the reference date once
       * for forecast and infos data
       */
        if (!newValue.enabled) {
          this.resetForecastData()
          this.resetInformationData()
        }
        /**
       * if the user disable replay for forecast,
       * reset it to the reference date
       */
        if (newValue.enabled && !newValue.forecast) {
          this.resetForecastData()
        }
        /**
       * if the user disable replay for weatherstation,
       * it's automatically computed
       */
        /**
       * if the user disable replay for information,
       * reset it to the reference date
       */
        if (newValue.enabled && !newValue.information) {
          this.resetInformationData()
        }
        if (newValue.enabled && !newValue.weatherstationavgtemp) {
          this.resetAvgTempData()
        }
      },
      deep: true
    },
    'replaySettings.weatherstation' (newValue) {
      if (this.replaySettings.enabled && newValue === true) {
        this.fetchData()
      }
    },
    'replaySettings.weatherstationavgtemp' (newValue) {
      if (this.replaySettings.enabled && newValue === true) {
        this.fetchData()
      }
    }

  }
})
